import '../styles/main.scss';

import autoInit, { mdcAutoInit } from '@material/auto-init/index';
import * as floatingLabel from '@material/floating-label/index';
import * as lineRipple from '@material/line-ripple/index';
import * as ripple from '@material/ripple/index';
import * as snackbar from '@material/snackbar/index';
import * as textField from '@material/textfield/index';
import * as topAppBar from '@material/top-app-bar/index';

autoInit.register('MDCFloatingLabel', floatingLabel.MDCFloatingLabel);
autoInit.register('MDCLineRipple', lineRipple.MDCLineRipple);
autoInit.register('MDCRipple', ripple.MDCRipple);
autoInit.register('MDCSnackbar', snackbar.MDCSnackbar);
autoInit.register('MDCTextField', textField.MDCTextField);
autoInit.register('MDCTopAppBar', topAppBar.MDCTopAppBar);

document.addEventListener('DOMContentLoaded', () => {
  mdcAutoInit();
  const snackbar = document.querySelector('.mdc-snackbar');
  if (snackbar) {
    snackbar.MDCSnackbar.open();
  }
});


// const isProduction = process.env.NODE_ENV === 'production';
// const serverUrl = isProduction ? '' : 'http://localhost:3000';
//const signInPageUrl = `${serverUrl}/auth/sign-in`;